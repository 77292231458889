<template>
  <div class="page-main" v-loading="loading">
    <el-form class="edit-select" ref="merchandiseForm" :model="info" :rules="merchandiseRules">
      <div class="edit-select-item">
        <el-form-item prop="orgId" label="所属组织">
          <organization-select :model="info" :disabled="propIsDisabled(info, 'orgId')" allLabel="请选择所属组织"></organization-select>
        </el-form-item>
        <el-form-item prop="uniqueCode" label="商品编码">
          <el-input v-model="info.uniqueCode" :disabled="propIsDisabled(info, 'uniqueCode')" maxlength="32"></el-input>
        </el-form-item>        
        <el-form-item v-if="!isRetail" prop="merchandiseName" label="商品名称">
          <el-input v-model.trim="info.merchandiseName" :disabled="propIsDisabled(info, 'merchandiseName')" maxlength="50" @input="handleInput"></el-input>
        </el-form-item>
        <el-form-item v-if="isRetail" prop="isTaxPreferential" label="是否享受特殊税率">
          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">
            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item" v-if="isRetail">
        <el-form-item prop="merchandiseName" label="商品名称">
          <el-input v-model.trim="info.merchandiseName" :disabled="propIsDisabled(info, 'merchandiseName')" @input="handleInput" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item prop="merchandiseCategory" v-if="isRetail" label="商品类型">
          <el-select v-model="info.merchandiseCategory" :disabled="propIsDisabled(info, 'merchandiseCategory')" placeholder="请选择商品类型">
            <el-option v-for="item in MerchandiseCategory" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxPreferentialContent" label="特殊税率内容">
          <el-select v-model="info.taxPreferentialContent" :disabled="propIsDisabled(info, 'taxPreferentialContent')">
            <el-option v-for="i in content" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="taxClassCode" label="税收分类编码" required>
          <el-input v-model.trim="info.taxClassCode" placeholder="请输入税收分类编码">
            <i slot="suffix" class="el-icon-more" style="color: #409eff; cursor: pointer" @click="handleChooseTaxClassCode"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="taxClassName" label="税收分类名称">
          <el-input v-model="info.taxClassName"></el-input>
        </el-form-item>
        <el-form-item prop="taxSign" label="含税标志">
          <el-select v-model="info.taxSign" :disabled="propIsDisabled(info, 'taxSign')">
            <el-option v-for="i in taxSigns" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="taxRateModel" label="税率（一般纳税人）" required>
          <el-select v-model="info.taxRateModel" :disabled="propIsDisabled(info, 'taxRate')">
            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxRateModel" label="税率（小规模纳税人）" required>
          <el-select v-model="info.ssTaxRateModel" :disabled="propIsDisabled(info, 'taxRate')">
            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="price" label="商品单价">
          <el-input v-model="info.price" :disabled="propIsDisabled(info, 'price')"></el-input>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item  prop="priceVariable" label="单价是否可修改">
          <el-select v-model="info.priceVariable" :disabled="propIsDisabled(info, 'priceVariable')">
            <el-option v-for="i in priceVariableOptions" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="specification" label="规格型号">
          <el-input v-model="info.specification" :disabled="propIsDisabled(info, 'specification')"></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="商品单位">
          <el-input v-model="info.unit" :disabled="propIsDisabled(info, 'unit')"></el-input>
        </el-form-item>
      </div>
      <div class="edit-select-item" v-if="!isRetail">
        <el-form-item  prop="isTaxPreferential" label="是否享受特殊税率">
          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">
            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  prop="taxPreferentialContent" label="特殊税率内容">
          <el-select  v-model="info.taxPreferentialContent" :disabled="propIsDisabled(info, 'taxPreferentialContent')">
            <el-option v-for="i in content" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  prop="taxPreferentialContent" label="">
          <div style="width:200px;"></div>
        </el-form-item>
      </div>
      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
          <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveMerchandise" type="primary">保存</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog append-to-body title="选择税收分类编码" :visible.sync="taxClassVisible" :show-close="false">
      <tax-class-code-mapping @handleCurrentGoods="handleCurrent"></tax-class-code-mapping>
    </el-dialog>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import TaxClassCodeMapping from './TaxClassCodeMapping';
import { insertMerchandise, updateMerchandise, checkUniqueCode, getFillSerialNo, getMiddleMerchandise } from '@/service/merchandise';
import { MERCHANDISE_TAX_RATE } from '@/content/merchandiseInfo';

export default {
  name: 'MerchandiseEdit',
  components: { OrganizationSelect, TaxClassCodeMapping },
  props: {
    isRetail: Boolean,
    merchandise: {
      type: Object,
      default: () => {}
    },
    content: Array,
    scene: undefined
  },
  watch: {
    'info.taxPreferentialContent'() {
      if (this.info.taxPreferentialContent != '免税' && this.info.taxPreferentialContent != '不征税') {
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
      if ((this.info.taxPreferentialContent == '免税' || this.info.taxPreferentialContent == '不征税') && this.info.taxPreferentialContent != '') {
        this.info.taxRateModel = this.info.taxPreferentialContent;
      }
    },
    'info.taxRateModel'() {
      if (this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' && this.info.taxRateModel != '' && (this.info.taxPreferentialContent == '免税' || this.info.taxPreferentialContent == '不征税')) {
        this.info.isTaxPreferential = '0';
      }
    },
    'info.isTaxPreferential'() {
      if (this.info.isTaxPreferential == '0') {
        this.info.taxPreferentialContent = '';
        // 税率重置
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
    }
  },
  computed: {
    priceDisabled() {
      var that = this.info;
      let price = this.info.price;
      if (price == null || price === '') {
        that.priceVariable = '1';
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      loading: false,
      remoteLoading: false,
      info: this.merchandise,
      middleKinds: [],
      MerchandiseCategory: [{ value: '', label: '全部' }, ...this.$store.state.enums['MerchandiseCategory']],
      taxRates: MERCHANDISE_TAX_RATE,
      isTaxPreferentialTypes: [
        { value: '1', label: '享受' },
        { value: '0', label: '不享受' }
      ],
      priceVariableOptions: [
        { value: '1', label: '是' },
        { value: '0', label: '否' }
      ],
      taxSigns: [
        { value: 0, label: '是' },
        { value: 1, label: '否' }
      ],
      merchandiseRules: {
        orgId: [{ required: true, message: '请选择组织！' }],
        merchandiseName: [
          { required: true, message: '请输入商品名称!' },
          { min: 1, max: 50, message: '长度为 1~50 个字符!', trigger: 'blur' }
        ],
        uniqueCode: [
          { required: true, message: '请输入商品编码!' },
          { validator: this.checkUnique, trigger: 'blur' }
        ],
        price: [
          {
            pattern: /^([-+])?\d+(\.[0-9]{1,8})?$/,
            message: '请输入正确的单价'
          }
        ],
        taxClassCode: [{ required: true, message: '请输入税收分类编码!' }],
        taxRateModel: [{ required: true, message: '请选择税率!' }],
        taxPreferentialContent: [{ validator: this.checkTaxPreferentialContent, trigger: 'blur' }]
      },
      taxClassVisible: false,
      saveBtnLoading: false
    };
  },
  created() {
    if (this.info.uniqueCode == undefined) this.getUniqueCode();
  },
  methods: {
    handleInput(e) {
      this.$forceUpdate();
    },
    // 中类名称触发选中
    handleChande(val) {
      this.info.parentId = val;
    },
    // 匹配中类名称
    handleMiddleKindQuery(val) {
      if (!this.info.orgId) {
        return this.toast('请先勾选所属组织信息', 'warning');
      }
      if (val == null || val == undefined || val == '') {
        return;
      }
      this.remoteLoading = true;
      getMiddleMerchandise({ orgId: this.info.orgId, name: val }).then((res) => {
        this.remoteLoading = false;
        if (res.success) {
          this.middleKinds = res.data;
        } else {
          this.middleKinds = [];
        }
      });
    },
    // 填充商品编码
    getUniqueCode() {
      this.loading = true;
      getFillSerialNo().then(({ success, data }) => {
        this.loading = false;
        if (success) this.info.uniqueCode = data;
      });
    },
    // 校验特殊税率
    checkTaxPreferentialContent(rule, value, callback) {
      if (!value && this.info.isTaxPreferential == '1') {
        return callback(new Error('特殊税率内容不能为空!'));
      }
      callback();
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },
    // 重置表单
    resetForm() {
      this.$refs.merchandiseForm.resetFields();
    },
    // 单选商品
    handleCurrent(row) {
      this.info.taxClassCode = row.taxClassCode;
      this.info.taxClassName = row.taxClassName;
      let taxRateModel = Number(row.taxRate.replace('%', ''));
      this.info.taxRateModel = taxRateModel ? taxRateModel / 100 : null;
      if (row.taxPreferentialContent != '') {
        this.info.isTaxPreferential = '1';
        this.info.taxPreferentialContent = row.taxPreferentialContent;
      }
      this.taxClassVisible = false;
    },
    // 商品编码唯一性校验
    checkUnique(rule, value, callback) {
      if (!value) return callback(new Error('商品编号不允许为空!'));
      const params = {
        uniqueCode: this.info.uniqueCode,
        id: this.info.id ? this.info.id : 0,
        orgId: this.merchandise.orgId
      };
      if (!this.merchandise.orgId) {
        return callback();
      }
      checkUniqueCode(params).then((res) => {
        if (res.success) {
          if (res.data) {
            callback(new Error('已存在相同编号商品'));
            return;
          }
        }
        callback();
      });
    },
    // 税收分类编码 补零
    addZeroTaxClassCode(taxClassCode) {
      while (taxClassCode.length < 19) {
        taxClassCode = taxClassCode + '0';
      }
      return taxClassCode;
    },
    // 点击提交 -> 参数校验
    handleSaveMerchandise() {
      this.$refs.merchandiseForm.validate((valid) => {
        if (valid) this.submitMerchandise();
      });
    },
    // 选择税收分类编码
    handleChooseTaxClassCode() {
      console.log(this.info,'this.info')
      let tax = this.propIsDisabled(this.info, 'taxClassCode');
      console.log(tax,'tax')
      if (tax == true) {
        return this.taxClassVisible;
      }
      this.taxClassVisible = true;
    },
    // 提交商品信息
    async submitMerchandise() {
      // loading保存按钮阻止反复提交
      this.saveBtnLoading = true;
      // 税收分类编码后面自动补充0到19位
      let realTaxClassCode = this.info.taxClassCode;
      this.info.taxClassCode = this.addZeroTaxClassCode(realTaxClassCode);

      // 税率补正
      this.info.taxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.taxRateModel;
      this.info.ssTaxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.ssTaxRateModel;
      // 新增字段给默认值
      if (this.info.isTaxPreferential == undefined || this.info.isTaxPreferential == '0') {
        this.info.isTaxPreferential = 0;
        this.info.taxPreferentialContent = '';
      }

      // 添加商品
      const { success } = this.info.id > 0 ? await updateMerchandise(this.info) : await insertMerchandise(this.info);
      const message = this.info.id > 0 ? '修改' : '新增';
      this.saveBtnLoading = false;
      if (success) {
        this.toast(`${message}商品信息成功`, 'success', () => this.handleCancel('success'));
        if (this.scene == 'Billing' && this.isRetail) {
          this.$emit('edit-finish', this.info);
        }
      } else {
        this.toast(`${message}商品信息失败`, 'error');
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
  padding-top: 2px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 24px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.page-main {
  padding: 0 24px;

  .tax-class-code {
    height: 32px;
    width: 170px;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    align-content: center;
    border: 1px solid #dcdfe6;
    justify-content: space-between;

    i {
      color: #3d94ff;
      line-height: 32px;
      margin-right: -4px;
    }
  }
}
</style>
